import numeral from 'numeral'

let formatNumber = (number) => {
  if (isNaN(number))
    return 0
  if (number) {
    if (Math.abs(number) > 1000)
      return numeral(number).format('0.0a')
    else 
    return numeral(number).format('0,0')
  }
  else return number
}

let formatNumberVerbose = (number) => {
  if (number > 0) {
    return formatNumber(number).replace('m', ' Million').replace('b', ' Billion').replace('k', 'K')
  }
  if (number === 0) {
    return 0
  }
}

let formatCurrencyNoCents = (number) => {
  if (Math.abs(number) < 0.001) {
    return '$0.00'
  } else if (Math.abs(number) > 1000)
    return numeral(number).format('$0,0')
  else
    return numeral(number).format('$0')
}

let formatFloat = (number) => {
  if (isNaN(number))
    return 0
  return numeral(number).format('0.00')
}

let toCamelCase = (string) => {
  let inputArray = string.split(' ')
  let result = "";
  for(let i = 0 , len = inputArray.length; i < len; i++) {
    let currentStr = inputArray[i];
    let tempStr = currentStr.toLowerCase();
    if(i != 0) {
        tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
     }
     result += tempStr;	
  }
  return result;
}

let formatCurrency = (number) => {
  if (Math.abs(number) < 0.001) {
    return '$0.00'
  } else if (Math.abs(number) > 1000) {
    return numeral(number).format('$0,0.00')
  } else {
    return numeral(number).format('$0.00')
  }
}

let isValidPositiveNumber = (input) => {
  if (input === undefined || input === null) {
    return false;
  } else if (typeof input === 'number') {
    return input >= 0;
  } else if (typeof input === 'string') {
    return input != "" && input >= 0;
  } else {
    return false;
  }
}
let readableSize = (number) => {
  if (number === 0) {
    return '0 B'
  }
  var i = Math.floor( Math.log(number) / Math.log(1024) )
  return ( number / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i]
}

export {
  formatNumber,
  formatNumberVerbose,
  formatFloat,
	toCamelCase,
	formatCurrency,
  isValidPositiveNumber,
  readableSize,
  formatCurrencyNoCents
}